<!-- @format -->

<template>
  <v-data-table
    ref="sortableTable"
    data-app
    :headers="headers"
    :items="list.data"
    sort-by="sortpos"
    class="elevation-1 container"
    :hide-default-footer="true"
    loading
    loading-text="Loading... Please wait"
    disable-pagination
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Startkinos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
              >Neuer Eintrag</v-btn
            >
            <v-btn color="primary" dark class="mb-2 mr-2" v-on="on"
              ><router-link to="/">Zurück</router-link></v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.stadt"
                      label="Stadt"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.kino"
                      label="Kino"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:body="{ items }">
      <draggable v-model="list.data" tag="tbody" @end="onEnd">
        <tr v-for="item in items" :key="item.id" class="sortableRow">
          <td class="px-1" style="width: 0.1%">
            <v-btn style="cursor: move" icon class="sortHandle"
              ><font-awesome-icon class="" :icon="['fas', 'bars']"
            /></v-btn>
          </td>
          <td>{{ item.stadt }}</td>
          <td>{{ item.kino }}</td>
          <td>
            <font-awesome-icon
              class="mx-1"
              :icon="['fas', 'edit']"
              @click="editItem(item)"
            />
            <font-awesome-icon
              class="mx-1"
              :icon="['fas', 'trash']"
              @click="deleteItem(item)"
            />
          </td>
        </tr>
      </draggable>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>

<script>
  import axios from 'axios'
  import draggable from 'vuedraggable'
  const fs = require('browserify-fs')

  export default {
    // middleware: 'auth',
    components: {
      draggable,
    },
    data: () => ({
      dialog: false,
      headers: [
        {},
        { text: 'Stadt', value: 'stadt', align: 'left' },
        { text: 'Kino', value: 'kino', sortable: false, align: 'left' },
        { text: 'Actions', value: 'action', sortable: false, align: 'left' },
      ],
      list: [],
      editedIndex: -1,
      editedItem: {
        stadt: '',
        kino: '',
      },
      defaultItem: {
        stadt: '',
        kino: '',
      },
    }),

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'Neuer Eintrag' : 'Eintrag editieren'
      },
    },

    watch: {
      dialog(val) {
        val || this.close()
      },
    },

    created() {
      this.initialize()
    },

    mounted() {
      this.$nextTick(() => {})
    },

    methods: {
      async getData() {
        const url = '/entwicklung/website-template/dist/api.php'
        await axios
          .get(url)
          .then(function (response) {
            this.list = response.data
          })
          .catch(function (error) {
            // Error handling
          })
      },
      updateData() {
        const url = '/api/db/update'
        axios.post(url, { sorted: this.list.data })
      },
      saveData(item) {
        const url = '/api/db/insert'
        axios.post(url, { item })
      },
      deleteData(item) {
        const url = '/api/db/delete'
        axios.post(url, { item })
      },

      initialize() {
        this.getData()
      },

      editItem(item) {
        this.editedIndex = this.list.data.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem(item) {
        const index = this.list.data.indexOf(item)
        if (confirm('Are you sure you want to delete this item?')) {
          this.list.data.splice(index, 1)
          this.deleteData(item)
        }
      },

      close() {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save() {
        if (this.editedIndex > -1) {
          Object.assign(this.list.data[this.editedIndex], this.editedItem)
          this.updateData()
        } else {
          this.list.data.push(this.editedItem)
          this.saveData(this.editedItem)
        }
        this.close()
      },

      onEnd(evt) {
        this.list.data.forEach((element, index) => {
          element.sortpos = index
        })
        this.updateData()
      },
    },
  }
</script>
